export class Encuestas{
    constructor(
        public id_registro: string,
        public apellidoM: string, 
        public apellidoP: string,
        public createAt: string,
        public email: string,
        public latitud: string,
        public longitud: string,
        public nombre: string, 
        public pregunta1: string,
        public pregunta2: string,
        public pregunta3: string,
        public pregunta4: string,
        public pregunta5: string,
        public seccion: string,
        public telefono: string, 
        public tipo: string, 
        public uidUser: string,
        public urlAudio: string,
        public versionapp: string,

    ){}
}

