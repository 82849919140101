// EncuestasXPorcentajeResponse.ts
export interface RespuestaSiNo {
    SI: string;
    NO: string;
}

export interface RespuestaMultiple {
    'PRI/PAN': string;
    MORENA: string;
    MC: string;
}

export class Porcentajes {
    constructor(
        public pregunta1: RespuestaSiNo,
        public pregunta2: RespuestaSiNo,
        public pregunta3: RespuestaSiNo,
        public pregunta4: RespuestaSiNo,
        public pregunta5: RespuestaMultiple
    ) {}
}

export class EncuestasXPorcentajeResponse {
    constructor(
        public OK: boolean,
        public total: number,
        public porcentajes: Porcentajes
    ) {}
}
