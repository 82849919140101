import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Encuestas } from '../models/encuestas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class EncuestadoresService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';


  constructor(private http: HttpClient) { }

  getEncuestadores(): Observable<Encuestas[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestadores`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.encuestad)).map(item => {
        return new Encuestas(
          item.id_registro,
          item.apellidoM,
          item.apellidoP,
          item.createAt,
          item.email,
          item.latitud,
          item.longitud,
          item.nombre,
          item.pregunta1,
          item.pregunta2,
          item.pregunta3,
          item.pregunta4,
          item.pregunta5,
          item.seccion,
          item.telefono,
          item.tipo,
          item.uidUser,
          item.urlAudio,
          item.versionapp,
        );
      });
    })
    );
  }



  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}