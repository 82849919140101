import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Encuestas } from '../models/encuestas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Cots } from '../models/cots';
import { Grafica } from '../models/grafica';
import { EncuestasXPorcentajeResponse, Porcentajes, RespuestaSiNo, RespuestaMultiple } from '../models/porcentaje'; // Ajusta la ruta según tu estructura de archivos




moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class EncuestasService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';


  constructor(private http: HttpClient) { }

  getEncuestas(): Observable<Encuestas[]> {
    return this.http.get<any>(`${environment.url_api}getEncuesta`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.encuesta)).map(item => {
        return new Encuestas(
          item.id_registro,
          item.apellidoM,
          item.apellidoP,
          item.createAt,
          item.email,
          item.latitud,
          item.longitud,
          item.nombre,
          item.pregunta1,
          item.pregunta2,
          item.pregunta3,
          item.pregunta4,
          item.pregunta5,
          item.seccion,
          item.telefono,
          item.tipo,
          item.uidUser,
          item.urlAudio,
          item.versionapp,
        );
      });
    })
    );
  }


  getEncuestasXMaps(email: string, date: string): Observable<Encuestas[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestaXMaps/${email}/${date}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.encuesta)).map(item => {
        return new Encuestas(
          item.id_registro,
          item.apellidoM,
          item.apellidoP,
          item.createAt,
          item.email,
          item.latitud,
          item.longitud,
          item.nombre,
          item.pregunta1,
          item.pregunta2,
          item.pregunta3,
          item.pregunta4,
          item.pregunta5,
          item.seccion,
          item.telefono,
          item.tipo,
          item.uidUser,
          item.urlAudio,
          item.versionapp,
        );
      });
    })
    );
  }

/*
  getEncuestaXTotal(): Observable<Cots[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestaXTotal`)
      .pipe(
        map(result => {
          return JSON.parse(JSON.stringify(result.Encuestadores)).map(item => {
            return new Cots(
              item.nombre,
              item.correo,
              item.telefono,
              item.Total_Encuestas_Hoy,
              item.Ultima_Encuesta,
              item.Ultima_E_latitud,
              item.Ultima_E_longitud,
              item.Ultima_Conexion,
              item.Ultima_U_latitud,
              item.Ultima_U_longitud,
            );
          });
        })
      );
  }*/

  getEncuestaXTotal(): Observable<Cots[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestaXTotal`).pipe(
      map(result => {
        const encuestadores = result.Encuestadores.filter(item => item.Total_Encuestas_Hoy !== 0);
        return encuestadores.map(item => {
          return new Cots(
            item.nombre,
            item.correo,
            item.telefono,
            item.Total_Encuestas_Hoy,
            item.Ultima_Encuesta,
            item.Ultima_E_latitud,
            item.Ultima_E_longitud,
            item.Ultima_Conexion,
            item.Ultima_U_latitud,
            item.Ultima_U_longitud
          );
        });
      })
    );
  }

  getEncuestadoresXTotal(): Observable<Cots[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestadoresXTotal`).pipe(
      map(result => {
        const encuestadores = result.Encuestadores.filter(item => item.Total_Encuestas_Hoy !== 0);
        return encuestadores.map(item => {
          return new Cots(
            item.nombre,
            item.correo,
            item.telefono,
            item.Total_Encuestas_Hoy,
            item.Ultima_Encuesta,
            item.Ultima_E_latitud,
            item.Ultima_E_longitud,
            item.Ultima_Conexion,
            item.Ultima_U_latitud,
            item.Ultima_U_longitud
          );
        });
      })
    );
  }

  getEncuestaTotalDiaX(): Observable<Encuestas[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestaTotalDiaX`).pipe(map(result => {
      return result.total
    }));
  }

  getEncuestasXGrafica(Mes: string): Observable<Grafica[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestasXGrafica/${Mes}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Dias)).map(item => {
        return new Grafica(
          item.DiaDelMes,
          item.Total,
        );
      });
    })
    );
  }

  getEncuestasXGraficaE(Correo: string, Mes: string): Observable<Grafica[]> {
    return this.http.get<any>(`${environment.url_api}getEncuestasXGraficaE/${Correo}/${Mes}`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Dias)).map(item => {
        return new Grafica(
          item.DiaDelMes,
          item.Total,
        );
      });
    })
    );
  }
  // public PAN = "PRI/PAN";

  getEncuestasXPorcentaje(): Observable<EncuestasXPorcentajeResponse> {
    return this.http.get<any>(`${environment.url_api}getEncuestasXPorcentaje`).pipe(
      map(result => {
        console.log(result); // Verificar la estructura de la respuesta del API
        const porcentajes = new Porcentajes(
          { SI: result.porcentajes.pregunta1.SI, NO: result.porcentajes.pregunta1.NO },
          { SI: result.porcentajes.pregunta2.SI, NO: result.porcentajes.pregunta2.NO },
          { SI: result.porcentajes.pregunta3.SI, NO: result.porcentajes.pregunta3.NO },
          { SI: result.porcentajes.pregunta4.SI, NO: result.porcentajes.pregunta4.NO },
          { 'PRI/PAN': result.porcentajes.pregunta5['PRI/PAN'], MORENA: result.porcentajes.pregunta5.MORENA, MC: result.porcentajes.pregunta5.MC }
        );
        console.log(porcentajes); // Verificar la estructura de los porcentajes
        return new EncuestasXPorcentajeResponse(result.OK, result.total, porcentajes);
      })
    );
  }
  

  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}