import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AuthentificationService } from '../services/authentification.service';

import { Observable } from 'rxjs';
import { UserApp } from '../models/user_app';
import { reject } from 'q';
import { Cots } from '../models/cots';


@Injectable({
    providedIn: 'root'
})

export class UserAppService {
    observable: Observable<UserApp[]>;
    public currentUser;
    public urlAPI;

    constructor(private http: HttpClient, private authenticationService: AuthentificationService) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    getUsuariosApp(): Observable<UserApp[]> {
        return this.http.get<any>(`${environment.url_api}getUsuariosApp`)
            .pipe(
                map(result => {
                    return JSON.parse(JSON.stringify(result.Usuarios_App)).map(item => {
                        return new UserApp(
                            item.id_user,
                            item.nombre,
                            item.correo,
                            item.contrasena,
                            item.telefono,
                            item.status,
                            item.createAt
                        );
                    });
                })
            );
    }

    getUsuariosAppEQ(): Observable<UserApp[]> {
        return this.http.get<any>(`${environment.url_api}getUsuariosAppEQ`)
            .pipe(
                map(result => {
                    return JSON.parse(JSON.stringify(result.Usuarios_App)).map(item => {
                        return new UserApp(
                            item.id_user,
                            item.nombre,
                            item.correo,
                            item.contrasena,
                            item.telefono,
                            item.status,
                            item.createAt
                        );
                    });
                })
            );
    }

    addUser(user: UserApp) {
        let data = {
            nombre: user.nombre,
            correo: user.correo,
            telefono: user.telefono,
            contrasena: user.contrasena
        }

        return this.http.post<any>(`${environment.url_api}AddUsuarioApp`, data)
            .pipe(
                map(result => {
                    if (result.OK) {
                        return result.Usuarios_App;
                    } else {
                        return reject;
                    }
                })
            );
    }

    updateUsers(user: UserApp) {
        let data = {
            nombre: user.nombre,
            correo: user.correo,
            telefono: user.telefono,
            contrasena: user.contrasena,
        }

        return this.http.put<any>(`${environment.url_api}updateUsuarioApp/${user.id_user}`, data)
            .pipe(map(result => {
                if (result.OK) {
                    return result;
                } else {
                    return reject();
                }
            }));
    }

    deleteUser(id_usuario: string) {
        return this.http.delete<any>(`${environment.url_api}deleteUsuarioApp/${id_usuario}`)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

}