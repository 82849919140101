import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NvoLaredo } from '../models/Nvolaredo';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

moment.locale('en');

@Injectable({
    providedIn: 'root'
})

export class NvoLService {
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';


    constructor(private http: HttpClient) { }

    getRegistroNvoL(): Observable<NvoLaredo[]> {
        return this.http.get<any>(`${environment.url_api}getRegistroNvoL`).pipe(map(result => {
            return JSON.parse(JSON.stringify(result.registro)).map(item => {
                return new NvoLaredo(
                    item.id_reg,
                    item.SECCION,
                    item.DF,
                    item.DL,
                    item.MUNICIPIO,
                    item.AREA,
                    item.ZONA,
                    item.AREA_INTERES,                   
                    item.LN_ABR_2024,
                    item.COLONIAS,
                    item.T_CASILLAS,
                    item.T_POBLACION,
                    item.T_POB18,
                    item.T_VIVIENDAS,
                    item.T_MANZANAS,
                    item.G22_PAN_COA,
                    item.G22_PRI,
                    item.G22_MORENA,
                    item.G22_MC,
                    item.G22_MAY,
                    item.G22_POS,
                    item.G22_GANADOR,
                    item.DF21_PAN_COA,
                    item.DF21_MORENA,
                    item.DF21_MC,
                    item.DF21_MAY,
                    item.DF21_POS,
                    item.DF21_GANADOR,
                    item.DL21_PAN,
                    item.DL21_PRI,
                    item.DL21_MORENA,
                    item.DL21_MC,
                    item.DL21_MAY,
                    item.DL21_POS,
                    item.DL21_GANADOR,
                    item.AYUN21_PAN,
                    item.AYUN21_PRI,
                    item.AYUN21_PRD,
                    item.AYUN21_MORENA,
                    item.AYUN21_MAY,
                    item.AYUN21_POS,
                    item.AYUN21_GANADOR,
                    item.AYUN21_PROM_LN,
                );
            });
        })
        );
    }

    public exportExcel(jsonData: any[], fileName: string): void {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, fileName);
    }

    private saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

}