export class NvoLaredo {
    constructor(
        public id_reg: string,
        public SECCION: string,
        public DF: string,
        public DL: string,
        public MUNICIPIO: string,
        public AREA: string,
        public ZONA: string,
        public AREA_INTERES: string,
        public LN_ABR_2024: string,
        public COLONIAS: string,
        public T_CASILLAS: string,
        public T_POBLACION: string,
        public T_POB18: string,
        public T_VIVIENDAS: string,
        public T_MANZANAS: string,
        public G22_PAN_COA: string,
        public G22_PRI: string,
        public G22_MORENA: string,
        public G22_MC: string,
        public G22_MAY: string,
        public G22_POS: string,
        public G22_GANADOR: string,
        public DF21_PAN_COA: string,
        public DF21_MORENA: string,
        public DF21_MC: string,
        public DF21_MAY: string,
        public DF21_POS: string,
        public DF21_GANADOR: string,
        public DL21_PAN: string,
        public DL21_PRI: string,
        public DL21_MORENA: string,
        public DL21_MC: string,
        public DL21_MAY: string,
        public DL21_POS: string,
        public DL21_GANADOR: string,
        public AYUN21_PAN: string,
        public AYUN21_PRI: string,
        public AYUN21_PRD: string,
        public AYUN21_MORENA: string,
        public AYUN21_MAY: string,
        public AYUN21_POS: string,
        public AYUN21_GANADOR: string,
        public AYUN21_PROM_LN: string,
    ) {}
}
