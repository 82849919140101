export class Cots {
    constructor (
        public nombre: string,
        public correo: string,
        public telefono: string,
        public Total_Encuestas_Hoy: string,
        public Ultima_Encuesta: string,
        public Ultima_E_latitud: string,
        public Ultima_E_longitud: string,
        public Ultima_Conexion: string,
        public Ultima_U_latitud: string,
        public Ultima_U_longitud: string,
        ) {}
}
